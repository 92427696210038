@import "src/assets/scss/material-dashboard-pro-react/variables";
.panel {
  padding-right: 15px;
  width: 100%;
}
.panel img {
  max-width: 100%;
  max-height: 100%;
}

.mapContainer {
  position: relative;
}

.panel input[type=button] {
    background-color: $criterion-orange;
    border: none;
    color: white;
    border-radius: 3px;
    position: relative;
    padding: 12px 30px;
    margin: .3125rem 1px;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0;
    will-change: box-shadow, transform;
    transition:
      box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
}

.panel {
  color: $criterion-blue;
}

.margin-top {
  margin-top: 10px;
  margin-bottom: 10px;
}

.margin-label {
  margin-top: 25px;
}
.margin-bottom {
  margin-top: 10px;
  margin-bottom: 30px;
}
.modal-title {
  color: #0f1a2b;
  font-weight: bold;
  font-size: 24px;
  justify-content: unset;
  margin-top: 36px;
}

.panel h2 {
  // background-image: linear-gradient(to right, $criterion-blue , white);
  font-weight: bold;
  // font-size: x-large;
  font-size: 24px;
}

.margin-onLabel {
  margin: 47px 0 26px 0;
}

.margin-radioOnLabel {
  margin: 35px 0 26px 0;
}

.color-grey {
  color: #7d7d7d;
  font-weight: 300;
}

.CustomDropDownClass {
  margin-top: 2px;
  margin-bottom: 20px;
}

.panel h3 {
  // background-image: linear-gradient(to right, $criterion-blue , white);
  font-weight: bold;
}

.panel table {
  width: 100%;
  margin-bottom: 100px;
}

.panel table th{
  padding: 5px;
}

.panel table td {
  padding: 5px;
  word-break: break-word;
}

.margin {
  margin: 43px 0 37px 0 !important;
}
.makeStyles-message-235 {
  background: rgba(193, 193, 193, 0.1);
  border: 1px solid rgba(193, 193, 193, 0.5);
  color: #737373;
  font-weight: 300;
  font-size: 12px;
  line-height: 135.69%;
}
// .panel tr:nth-child(even){background-color: #f2f2f2;}

// .panel tr:hover {background-color: #ddd;}

.panel th {
  border-bottom: 1px solid #d8d8d8; // grey color
  text-align: left;
  font-weight: 300;
  padding: 15px 0px 5px 0px;
  color: #7b7b7b; // grey color
}

.panel p {
  margin: 0px 0px 10px;
}

.panel p img {
  margin-bottom: 15px;
  margin-top: 15px;
}

.panel p span img {
  margin-bottom: 0px;
  margin-top: 0px;
}

.fadeOut {
  opacity: 0.1;
  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
}
.fadeIn {
  opacity: 1;
  transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
}
.Notes {
  background: rgba(193, 193, 193, 0.1) !important;
  border: 1px solid rgba(193, 193, 193, 0.5) !important;
  color: #737373;
  font-size: 12px;
}

.launch-demo{
  .card-main-content{
    display: flex;
    flex-direction: column;
    height: 90%;
    justify-content: space-between;
  }
  .lock {
    width: 20px;
    height: 20px;
    margin-left: 10px;
  }
  .card-content-heading {
    font-size: 16px;
    font-weight: bold;
    color: rgb(15, 26, 43);
    // cursor: pointer;
  }
  .card-description{
    margin-top: 20px;
  }
}

.MuiPaper-elevation1{
  box-shadow: unset !important;
}

.Modal-sandBox {
  display: flex;
  align-items: center;
  justify-content: center;
  .dropdown {
    background: #f1f1f1;
    height: 40px;
    &:after {
      background-image: url("../../../../assets/img/dropdown-icon.svg");
      content: "";
    }
  }
  .MuiInput-underline:before {
    border-bottom: unset;
  }
  .space-betwwen {
    margin-top: 16px;
  }
  .closeIcon2 {
    position: absolute;
    margin-top: -186px;
    margin-left: 36px;
    cursor: pointer;
    color: #ffffff;
    font-size: 2rem;
  }
  .closeIcon3 {
    position: absolute;
    margin-top: -276px;
    margin-left: 36px;
    cursor: pointer;
    color: #ffffff;
    font-size: 2rem;
  }
  .label {
    color: #797979;
    font-size: 16px;
    line-height: 140%;
    margin-bottom: 8px;
    margin-top: 20px;
  }
  .Notes {
    background: rgba(193, 193, 193, 0.1) !important;
    border: 1px solid rgba(193, 193, 193, 0.5) !important;
    color: #737373;
    font-size: 12px;
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-width: unset;
    border-bottom-color: unset;
    border-bottom: unset;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: unset;
  }
  .closeIcon {
    position: absolute;
    margin-top: -142px;
    margin-left: 36px;
    cursor: pointer;
    color: #ffffff;
    font-size: 2rem;
  }
  .grid-container {
    position: absolute;
    width: 400px;
    background-color: #ffffff;
    padding: 16px 32px 24px;
  }
}
.access-lab {
  font-weight: 400;
  padding: 0 20px;
  hr{
    display: none;
  }
  .teminate-button{
    margin: 0;
    top: auto;
    right: auto;
    bottom: 20;
    left: auto;
    border-radius:0px;
  }
  .go-back {
    cursor: pointer;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    color: #818181;
    margin-top: 23px;
    display: block;
  }
  .lab-title {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 42px;
    color: #000000;
  }
  .sidebar-main{
    padding-left: 0px !important;
  }
  .card-body{
    padding-left: 34px !important;
  }
  .suspend-button{
    border-color: #F96B13;
    color: #F96B13;
    width: 175px;
  }
  .div-button{
    margin: 7% 0;
  }
  .terminate-button{
    border-color: #F92113;
    color: #F92113;
    width: 175px;
  }
  .time-status {
    // display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-left: -15px;
    .setting-label{
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 129.69%;
      color: #171717;

    }
    .status {
      display: flex;
      align-items: center;
      .check-icon {
        line-height: 16px;
        margin-left: 7px;
      }
    }
    .time {
      display: flex;
      align-items: center;
      .time-left {
        margin: 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        display: flex;
        align-items: center;
        color: #0F1A2B;
      }
      .add-hours {
        cursor: pointer;
        font-family: Roboto;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        align-items: center;
        color: #F96B13;
        display: flex;
        margin-left: 20px;
      }
    }
  }
  .cardStyle{
    padding: 0 10px;
  }
  .sidebar {
    min-height: 450px;
    display: block;
    font-size: 14px;
    margin-bottom: 150px;
    margin-top: 30px;
  }
  .next-prev {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap; 
    gap: 10px; 
  }
  .button-group {
    display: flex;
    gap: 10px; 
  }
  @media only screen and (max-width: 992px) {
    padding: 5px;
    .time-status {
      margin-top: 15px;
    }
  }
  @media (max-width: 575.98px) {
    .sidebar{
      margin-bottom: 0;
      min-height: 0;
    }
    .AccessLabLandingPage-cardStyle-177{
      padding: 0 !important;
    }
    .makeStyles-cardStyle-240{
      padding-left: 0 ;
      padding-right: 0;
    }
    .cardStyle{
      padding: 0 !important;
    }
    .card-padding{
      padding: 0 !important;
    }
  }
}

// .learning-card{
//   .MuiGrid-root{
//     text-align: center;
//     ul li {
//       text-align: left;
//       padding-left: 160px;
//     }
//   }
// }

.block-ellipsis {
  font-family: Roboto;
  display: inline-block;
  display: -webkit-box;
  max-width: 100%;
  margin: 0 auto;
  font-size: 14px;
  line-height: 1.5;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: '...';
}
.block-ellipsis + a {
  display: inline-block;
    vertical-align: bottom;
    margin-top: -1px;
  }
.panelheight{
  height: 85px;
}

.position{
  padding-top: 16px; 
}

.positionActive{
  padding-top: 28px; 
}

.MuiTypography-h2{
  font-size: none !important;
  font-family: Roboto !important;
  font-weight: 500 !important;
  line-height: 1.8 !important;
}